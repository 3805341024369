@font-face {
  font-family: 'PP Mori';
  src: url('../fonts/pp-mori/PPMori-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PP Mori';
  src: url('../fonts/pp-mori/PPMori-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PP Mori';
  src: url('../fonts/pp-mori/PPMori-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'PP Mori';
  src: url('../fonts/pp-mori/PPMori-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'PP Mori';
  src: url('../fonts/pp-mori/PPMori-Extralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'PP Mori';
  src: url('../fonts/pp-mori/PPMori-ExtralightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}
